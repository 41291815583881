.formBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1rem;
  gap: 2rem;
  margin: 5%;
  padding: 5vh;
  background-color: var(--card-bg-color);
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.3);
}