.loader-please-wait, .loader-signin-text {
    font-family: "ARTIFAKT ELEMENT REGULAR", Arial, sans-serif;
    color: black;
}

.main-adm{
    /* display: flex;
    width: 100%;
    height: 100%;
    padding: 1rem;
    justify-content: center;
    align-items: flex-start; */
}

.loader-padding {
    padding: 48px 0;
}
.loader-img {
        height: 100px;
        width: 100px;
    }
.loader-please-wait, .loader-signin-text {
    font-family: "ARTIFAKT ELEMENT REGULAR", Arial, sans-serif;
}
.loader-signin-text {
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    color: #000;
    padding-bottom: 8px;
}
.loader-please-wait {
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: #666666;
}