/* .item-search{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
} */

.search-container{
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    flex-direction: column;
    margin: 24px 0;
}


@media only screen and (min-width: 40em) {
}

@media only screen and (min-width: 48em) {
}

@media only screen and (min-width: 52em) {
}

@media only screen and (min-width: 64em) {
    .search-container{
        display: grid;
        grid-template-columns: 1.5fr 4.5fr;    
        gap: 20px;
    }

}

@media only screen and (min-width: 80em) {
    .search-container{
        display: grid;
        grid-template-columns: 1.5fr 4.5fr;    
    }
}