.metadata-info-container {
    width: 100%;
    margin-top: 1rem;
}

.card-metadata {
    background-color: #fafcfe;
    height: 100%;
    padding: 24px;
}

.card-content-metadata {
    display: flex;
    flex-wrap: wrap;
    margin-top: 24px;
    gap: 16px;
}

.card-metadata-subgrid,
.card-metadata-subgrid-tipo-doc,
.card-metadata-subgrid-info-sat {
    width: 100%;
}

.textfield {
    background-color: var(--input-disabled-bg-color) !important;
    border-radius: 14px;
    color: #737d85;
    width: 100%;
}

@media only screen and (min-width: 40em) {
}

@media only screen and (min-width: 48em) {
    .card-content-metadata {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .card-metadata-subgrid {
        grid-area: 1 / 1 / 2 / 3;
    }

    .card-metadata-subgrid-tipo-doc {
        grid-area: 2 / 1 / 2 / 4;
    }

    .card-metadata-subgrid-info-sat {
        grid-area: 5 / 1 / 5 / 4;
    }
}

@media only screen and (min-width: 52em) {
    .card-content-metadata {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(auto, 1fr);
    }

    .card-metadata-subgrid {
        grid-area: 1 / 1 / 2 / 3;
    }

    .card-metadata-subgrid-tipo-doc {
        grid-area: 2 / 1 / 2 / 4;
    }

    .card-metadata-subgrid-info-sat {
        grid-area: 5 / 1 / 5 / 4;
    }
}

@media only screen and (min-width: 64em) {
}

@media only screen and (min-width: 80em) {
}
