@import "rsuite/dist/rsuite.css";

.rs-picker-toggle-wrapper {
    width: 100% !important
}

.MuiInputBase-root {
    border-radius: 14px !important;
    padding-right: 14px !important;

}
.rs-picker-daterange{
    border-radius: 14px ;

}

/* Estilo padrão para a borda */
.rs-input-group, .rs-input {
    border-radius: 14px ;
    border-color: #b4c4d4;

}

.rs-input-group.rs-input-group-inside {
    width: 100%;
    height: 40px !important;
}

/* Estilo quando estiver em estado de hover */
.rs-input-group:hover,
.rs-input:hover {
    border-color: #000;
}

.rs-date-range-input::placeholder {
    color: #b7c7d5;
}

.fields-container-geral {
    margin: 24px 0;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    gap: 12px 24px;
}

.subgrid-pec {
    display: flex;
    gap: 12px 24px;
    flex-direction: column;
}

.sub-grid-mun,
.sub-grid-contratoslg,
.sub-grid-contrato {
    display: flex;
    gap: 12px 24px;
    flex-direction: column;
    display: inline-grid;
}

.buttons-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin: 24px 14px;
}

.custom-tab1 p {
    width: 100% !important;
    white-space: normal;
}

/*
.MuiTabs-scrollButtons.Mui-disabled {
    opacity: 10 !important;
  }

*/

.MuiBox-root {
    display: contents !important;
}

.MuiButtonBase {
    display: flex !important;
}

.Tab {
    display: flex !important;
}

.ContentPanel {
    width: 100%;
}

@media only screen and (min-width: 40em) {

    .subgrid-num {
        gap: 12px 24px;
        display: inline-grid;
    }

}

@media only screen and (min-width: 55em) {
    .fields-container-geral {
        grid-template-columns: repeat(2, 1fr);
    }

    .sub-grid-mun,
    .sub-grid-contratoslg {
        grid-row: 2;
        display: grid;
    }

    .sub-grid-contrato {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 12px 24px;
    }

}

@media only screen and (min-width: 80em) {
    .fields-container-geral {
        grid-template-columns: repeat(4, 1fr);
    }

    .sub-grid-tipo {
        grid-row: 2;
        grid-column: 4 / span 1;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 12px 24px;
    }

    .sub-grid-contratoslg {
        grid-row: 2;
        grid-column: 1 / span 1;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 12px 24px;
    }

    .sub-grid-mun {
        grid-row: 1;
        grid-column: 2 / span 1;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 12px 24px;
    }

    .sub-grid-contrato {
        grid-row: 1;
        grid-column: 4 / span 1;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 12px 24px;
    }
}

@media (max-width:599.95px) {
    .css-11xf28z-MuiTabs-root .MuiTabs-scrollButtons {
        display: flex;
    }
}