@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.light-mode {
    --font-color: #718797;
    --body-bg-color: #edf8fd;
    --card-bg-color: #fafcfe;
    --card-selected-bg-color: #d5ecff;
    --input-disabled-bg-color: #ebeced;
    --input-font-color: #737d85;
  }

  .dark-mode {
    --font-color: #cccccc;
    --body-bg-color: #101212;
    --card-bg-color: #1e1e1e;
    --card-selected-bg-color: #082d4a;
    --input-disabled-bg-color: #292929;
    --input-font-color: #969696;
  }

  .MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation4.MuiAppBar-root.MuiAppBar-colorPrimary.MuiAppBar-positionRelative.header{
    background-image:none !important;
  }

  .MuiAvatar-root {
    color: #fff !important;
  }

.MuiCardContent-root {
    /* height: 84vh; */
    overflow-y: auto !important;
}

.MuiAppBar-root {
    box-shadow: none !important;
}

body {
    background-color: var(--body-bg-color);
    /* background-color: #eefaff; */
    font-family: "Roboto", sans-serif;
}

.loading {
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: fixed;
    z-index: 9999;
    background: grey;
    opacity: 0.5;
}

.adsk-viewing-viewer {
    height: 70% !important;
    width: 55% !important;
    margin-top: "auto";
    overflow: hidden;
}

.MuiAutocomplete-endAdornment {
    display: none;
}
/* 
.canvas-wrap {
    width: 1465px;
    height: 509px;
} */

/* .MuiTypography-root {
    font-size: 0.9rem !important;
} */

.MuiListItemButton-root {
    right: 35px !important;
}

.MuiListItemButton-root:hover {
    background-color: white !important;
}

.MuiListItemAvatar-root {
    min-width: 0px !important;
    width: 35px !important;
}

.MuiCard-root {
    overflow: hidden visible !important;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #bcbcbc;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #8d8d8d;
}

.MuiOutlinedInput-root.MuiInputBase-sizeSmall
    .MuiAutocomplete-input {
    text-transform: uppercase;
}

.rs-input {
    background: transparent;
    font-size: 16px;
    color: var(--input-font-color) !important;
}

@media only screen and (min-width: 40em) {
    .adsk-viewing-viewer {
        width: 70% !important;
        height: 75% !important;
    }
}

@media only screen and (min-width: 48em) {
}

@media only screen and (min-width: 52em) {
    .adsk-viewing-viewer {
        width: 77% !important;
    }
}

@media only screen and (min-width: 64em) {
    .adsk-viewing-viewer {
        width: 58% !important;
        height: 68% !important;
    }
}

@media only screen and (min-width: 80em) {
    .adsk-viewing-viewer {
        width: 62% !important;
        height: 66% !important;
    }
}
