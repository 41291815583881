.file-card{
    min-height: 32px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 8px 1px;
    cursor: pointer;
}

.file-card-selected{
    background: var(--card-selected-bg-color) !important;
}

.file-card-name{
    font-size: 13px;
    color: var(--font-color);
    cursor: pointer;
}

.file-card-name-selected{
    font-weight: bold;
}

@media only screen and (min-width: 40em) {
}

@media only screen and (min-width: 48em) {


}

@media only screen and (min-width: 52em) {

}

@media only screen and (min-width: 64em) {

}

@media only screen and (min-width: 80em) {
    

}